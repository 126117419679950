/* Reset default margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set base font size and font family */
body {
  font-size: 16px;
  font-family: 'Work Sans', sans-serif;
}

/* @import url('react-confirm-alert/src/react-confirm-alert.css'); */

/* @media only screen and (max-width: 767px) {
  .react-confirm-alert-body {
    width: auto;
  }
} */

/* Header styles */
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distribute content evenly */
  padding: 20px;
  background-color: white;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  position: fixed;
  height: 10%;
  top: 0; /* Position at the top of the page */
}


.adaptable-logo {
  height: 10vh;  /* Set a responsive height based on viewport height */
  /* max-height: 80px; /* Cap the maximum height */
  /* min-height: 80px; Minimum height for very small screens */
  width: auto; /* Maintain aspect ratio */
}

@media (max-height: 500px) {
  .adaptable-logo {
    height: 8vh; /* Reduce height slightly for very short screens */
  }
}






.header-certificate{
  display: flex;
  height: 10%; /* La hauteur souhaitée pour le header */
  position: relative;
  margin-left: 50%;
}

.header-certificate img {
  height: 10%;
  width: 20%;
}

.header-certificate-rc {
  display: flex;
  height: 8%; /* La hauteur souhaitée pour le header */
  position: relative;
  margin-left: 50%;
}



.header-certificate-rc img {
  height: 8%;
  width: 20%;
}

.QRcode {
  position: fixed;
  top:20px ;
  right: 20px;

}
.header-certificate-box{
  background-color: black;
  border: 1px solid black;
  padding: 5px;
}
.header-certificate-box-rc {
  background-color: black;
  border: 1px solid black;
  padding: 5px;
}

.request-info-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.request-info-container-rc {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  font-size: 14px;
}

.content-contenair{
  display: flex;

}
.content-text {
  font-size: 14px;
  max-width: 100%;
}

.content-image, .content-text{
  width:40% ;
}

.Certificate-QRCode{
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  padding: 50px;
}

.adaptable-logo {
  max-width: 5vh; /* Set the maximum width to 10% of the header width */
  max-height: auto;
  margin-right: 10px; /* Set the right margin to 80% of the header width */
  margin-top: 5px;
  min-width: 20px;
  min-height: 20px;
}

.app-title {
  font-size: 30px;
  text-align: center;
  margin: 0; /* Reset margin */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.menu {
  position: relative;
  display: inline-block;
  margin-top: 5px;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  z-index: 1;
  min-width: 110px; /* Adjust the width as needed */
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 10px; /* Add padding to the menu items */
  cursor: pointer; /* Add pointer cursor for better usability */
}

.menu-item:hover,
.menu-item:focus,
.menu-item:active {
  background-color: #f0f0f0; /* Change the background color on hover, focus, and active */
}

.menu-item:active {
  color: #fff; /* Change text color for better visibility on active state */
}

/* Add this CSS to ensure consistent icon size */
.menu-icon {
  width: 20px; /* Define the width as needed */
  height: 20px; /* Define the height as needed */
  margin-left: 10px; /* Add margin between icon and text if needed */
  vertical-align: middle; /* Align the icon vertically */
}

/* CSS for the Landing component */
.landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh; /* Ensures it takes the full viewport height */
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden; /* Prevents accidental scrolling */
}

.landing .content {
  text-align: center;
}

.landing h2, .landing p {
  max-width: 80%;
  margin-bottom: 10px;
}


.landing .login-button {
  width: 200px;
  margin-top: 20px; /* Add margin-top to create space between text and button */
}

/* Center the Google login button properly */
.google-login {
  background-color: #333;
  color: white;
  width: 80%;
  max-width: 300px;
  padding: 10px;
  border-radius: 50px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.google-login:hover {
  background-color: #555;
}

/* Adjust the focus style as needed */
.google-login:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 5px #fff; /* Example focus style */
}

/* Disabled state */
.google-login:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.neotex-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

.neotex-logo-container img {
  max-width: 70px;
  height: 70px;
}

.fixed-bottom {
  position: fixed;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: white; /* Match your background */
  padding: 10px 0;
  font-size: 14px;
}

/* CSS for the Consent component */
.consent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 10px;
}

.consent .content {
  text-align: center;
  /* font-size: calc(1vw + 2vh); */
}

.consent h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.consent input[type="checkbox"] {
  width: 1.5em;
  height: 1.5em;
  margin-right: 5px;
}

.facebook-login-button {
  background-color: #1877f2; /* Facebook blue color */
  color: white; /* White text */
  padding: 10px 20px; /* Add padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Make the button full width */
  cursor: pointer; /* Change cursor to pointer */
  margin-top: 20px;
  transition: background-color 0.3s ease;
  z-index: 1000;
}

.facebook-login-button:hover {
  background-color: #0060cc; /* Darker blue on hover */
}

/* General text styling */
.responsive-text {
  font-size: 1.5rem;
  line-height: 1.6;
  font-weight: normal;
  text-align: justify;
  padding: 0 10%;
}

/* Centering the checkbox and label horizontally */
.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
}

/* Styling for the label next to checkbox */
.responsive-label {
  font-size: 1.5rem;
  margin-right: 8px; /* Space between checkbox and label */
}

/* Adjust font sizes for smaller screens */
@media (max-width: 768px) {
  .responsive-text,
  .responsive-label {
    font-size: 1.2rem;
  }

  .responsive-text {
    padding: 0 5%;
  }
}

@media (max-width: 480px) {
  .responsive-text,
  .responsive-label {
    font-size: 1rem;
  }

  .responsive-text {
    padding: 0 3%;
  }
}





/* Additional styles for the text */
span {
  vertical-align: middle; /* Align the text vertically */
}

.subtitle {
  font-weight: bold;
  margin-top: 12vh; /*Create space between header and subtitle */
  margin-left: 30px; /* Adjust the left margin as needed */
  color:#4c58c5; /* Text color for the subtitle */
}


.multistep-menu-container {
  background-color: #f2f2f2; /* Light gray background color */
  border-radius: 50px; /* Rounded corners */
  padding: 10px 5px; /* Padding for inner content */
  margin: 5px 20px 0; /* Top margin increased to add space from header */
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1); /* Add shadow effect */
  overflow-x: auto; /* Enable horizontal scrolling if needed */
  z-index: 999;
}

/* MultiStepMenu styles */
.multi-step-menu ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: flex-start; /* Distribute circles evenly */
  max-width: calc(100% - 40px); /* Adjust the width based on padding */
  align-items: center; /* Align circles vertically */
}

.multi-step-menu li {
  flex: 0 0 auto; /* Prevent circles from stretching */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: blue;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  border: 1px solid transparent;
}

.multi-step-menu li.active {
  background-color: blue;
  color: white;
  border-color: white; /* Remove border for active step */
}

/* Blue border for non-active steps */
.multi-step-menu li:not(.active) {
  border-color: rgb(91, 91, 136);
}

.container {
  height: 100vh; /* Set the height of the container to 100% of the viewport height */
  position: relative; /* Ensure the container serves as the positioning context for absolute positioning */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Form styles */
.form {
  top: 20%; /* Adjust the top position as a percentage of the container's height */
  padding: 30px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0; /* Remove margin-top */
  /* font-size: calc(1vw + 2vh); */
  position: absolute; /* Change to absolute positioning */
}

.form-products {
  top:8%; /* Adjust the top position as a percentage of the container's height */
  padding: 30px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0; /* Remove margin-top */
  /* font-size: calc(1vw + 2vh); */
  position: absolute; /* Change to absolute positioning */
}

/* Form title styles */
.form h2 {
  text-align: center; /* Center align the form titles */
  margin-bottom: 15px; /* Add spacing below form title */
  font-size: calc(1vw + 2vh);
}

.form-financialProduct h2 {
    position: absolute;
    bottom: 400px;
    text-align: justify;
    left: 50%;
    transform: translateX(-50%);
  }

.form-group {
  margin-bottom: 25px; /* Increase spacing between form elements */
  text-align: left; /* Left-align form elements */
  width: 60vw;
  /* max-width: 300px; Set max-width for form elements */

}

.form-group label {
  display: block;
  margin-bottom: 8px; /* Adjust margin below labels */
  position: center;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px; /* Adjust padding for inputs/selects */
  border-radius: 5px;
  border: 1px solid #ccc;
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 1;
}

.content-wrapper {
  margin-top: 50px; /* Adjust the top margin to accommodate the fixed header */
}

.forms-wrapper {
  background-color: #ffffff;
  padding: 10px;
}

.submit-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  /* padding: 10px; */
  padding-top: 100px;
}

.white-banner {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: white;
  bottom: calc(50px + 10px);
  z-index: 0;
}

.submit-button-fixed {
  position: fixed;
  width: 200px;
  height: 10px;
  /* padding: 15px; */
  border: 1px;
  padding: 30px;
  border-radius: 20px;
  font-size: 3.2vw;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  bottom: 7%;
  margin-top: 10px;
  background-color: #d49292;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.request-button-fixed {
  position: fixed;
  width: 30vw;
  height: 10vh;
  /* padding: 15px; */
  border: 1px;
  padding: 30px;
  border-radius: 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  bottom: 70px;
  margin-top: 20px;
  background-color: #4CAF50;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.download-button-fixed {
  position: fixed;
  width: 50px;
  height: 40px;
  margin-left: 250px;
  margin-top: 20px;
  background-color: #ffffff;
}

/* Submit button styles */
.submit-button {
  width: 35vw;
  height: 8vh;
  padding: 15px;
  border: none;
  border-radius: 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  background-color: #7a7777;
  color: white;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 25%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Media query for responsiveness in footer */
  @media (max-width: 768px) {
    margin-top: 30%;
  }
  @media (max-height: 768px) {
    margin-top: 20%;
  }
}

.submit-button:hover {
  background-color: #45a049; /* Darken button slightly on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Adjust shadow on hover */
}
.submit-button:selected {
  background-color: #1f4c22; /* Darken button slightly on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Adjust shadow on hover */
}
/* Gray color for disabled buttons */
.submit-button:disabled {
  background-color: #ccc; /* Gray background color */
  color: #666; /* Darker gray text color */
  /* cursor: not-allowed; Show not-allowed cursor */
}

/* Submit button 2 styles */
.submit-button2 {
  width: 200px; /* Adjust width as needed */
  padding: 15px; /* Adjust padding for better appearance */
  border: none;
  border-radius: 20px;
  font-size: 18px; /* Adjust font size */
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Add transitions for smoother effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add shadow effect */
  margin-top: 100px; /* Keeps the button 50px above the footer */

  /* Media query for responsiveness in footer */
  @media (max-width: 768px) {
    /* Adjust button size for smaller screens */
    width: 100px; /* Adjust width as needed */
    font-size: 16px; /* Adjust font size */
  }
  @media (max-height: 768px) {
    /* Adjust button size for smaller screens */
    margin-top: 140px; /* Keeps the button 50px above the footer */
  }
}
.submit-button2:hover {
  background-color: #45a049; /* Darken button slightly on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Adjust shadow on hover */
}
.submit-button2:selected {
  background-color: #1f4c22; /* Darken button slightly on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Adjust shadow on hover */
}
/* Gray color for disabled buttons */
.submit-button2:disabled {
  background-color: #ccc; /* Gray background color */
  color: #666; /* Darker gray text color */
  /* cursor: not-allowed; Show not-allowed cursor */
}

/* Footer styles */
.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #f0f0f0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  height: 8%;
}

.pdf-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: 'relative';
}

.footer-certificate {
  position: relative;
  width:  100%;
  max-height: 120px;
  /* margin-top: auto; Push the footer to the bottom of the page */
  margin-bottom: 5px;
}

.footer-certificate-rc {
  position: relative;
  width:  100%;
  max-height: 120px;
  /* margin-top: auto; Push the footer to the bottom of the page */
  margin-bottom: 5px;
}

.keys{
  display: inline;
}

/* Footer button styles */
.footer button {
  width: 50px;
  height: 50px; /* Set button height as a percentage of its width to make it circular */
  padding: 0; /* Remove padding to make the button circular */
  margin: 0 5px; /* Adjust margin for spacing between buttons */
  border: none;
  border-radius: 100%; /* Set border-radius to make the button circular */
  font-size: 2vw; /* Set font size in viewport width to make it relative */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Media query for responsiveness in footer */
  @media (max-width: 768px) {
    /* Adjust button size for smaller screens */
    width: 50px;
    height: 50px;
    font-size: 20px;
  }

  @media (max-width: 480px) {
    /* Adjust button size for smaller screens */
    width: 50px;
    height: 50px;
    font-size: 20px;
  }

  @media (max-height: 600px) {
    /* Adjust button size for smaller screens */
    width: 30px;
    height: 30px;
    font-size: 20px;
  }

  @media (max-height: 400px) {
    /* Adjust button size for smaller screens */
    width: 15px;
    height: 15px;
    font-size: 20px;
  }
}

.selected {
  background-color: #132f14; /* Change the background color when selected */
}


/* Specific button styles */
.footer button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.footer button:nth-child(1) {
  background-color: #333; /* Blue color for Back button */
  color: white;
}

.footer button:nth-child(2) {
  background-color: #333; /* Green color for Home button */
  color: white;
}

.footer-text {
  font-size: 1.2vw;  /* Small font size */
  color: #333;         /* Dark text for contrast */
  text-align: center;
  line-height: 1.4;
  margin: 0;
  padding: 5px 10px;
  flex: 1;             /* Allow it to fill available space in the flex container */
}

.symbol {
  font-size: 4vh /* Set font size in viewport width to make it relative */
}

.form-container {
  max-width: 400px;
  margin: 0 auto;
}

.button-container {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Distribute items along the main axis with space between */
}

.download-button {
  background-color: transparent;
  margin-left: 2px; /* Adjust margin as needed */
  background: none; /* Remove background */
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.download-button img {
  width: 40px;
  height: 40px;
}

input,
select,
button {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 5px;
}

input:hover,
input:focus,
select:hover,
select:focus {
  border: 2px solid #4caf50; /* Highlight color on hover or focus */
}

.radio-group {
  margin-bottom: 10px;
}

.toggle-switch {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.toggle-switch label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.toggle-switch input {
  display: none;
}

.toggle-switch .slider {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  border-radius: 12px;
  background-color: #ccc;
  transition: background-color 0.3s;
}

/* .toggle-switch input:checked + .slider { */
  /* background-color: #4caf50; Highlight color when switch is toggled */
/* } */

button {
  /* background-color: #4caf50; */
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* button:hover {
  background-color: #45a049; /* Highlight color on hover */
/* } */

.camera {
  max-width: 100%; /* Make the canvas responsive */
  max-height: 100%; /* Make the canvas responsive */
  margin: 0 5%; /* 5% space on left and right */

  /* Media query for responsiveness */
  @media screen and (max-width: 768px) {
    margin: 0 5%; /* 5% space on left and right */
  }
}

.green-rectangle {
  width: 60%;
  height: 60%;
  border: 5px solid green;
  margin: auto; /* Center horizontally */
}

.control-buttons {
  display: flex;
  justify-content: space-around;
  width: 400px,
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Add space between logo and text */
}

.logo {
  width: 30%;
  height: auto;
}

.welcome-back {
  /* font-size: 32px; */
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 80%;
  display: flex;
  flex-direction: column;
}

/* Media queries for responsiveness */
@media (max-width: 768px), (max-height: 600px) {
  .logo {
    width: 40%;
  }
  .welcome-back {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .logo {
    width: 40%;
  }
  .welcome-back {
    font-size: 16px;
  }
}

@media (max-height: 750px) {
  .logo {
    width: 40%;
  }
  .welcome-back {
    font-size: 20px;
  }
}
@media (max-height: 430px) {
  .logo {
    width: 20%;
  }
  .welcome-back {
    font-size: 20px;
  }
}

.auth-button {
  margin-top: 5px;
  /* border-radius: 20%; 20% rounded corners */
  background-color: white;
}

.auth-image {
  width: auto; /* Full width */
  border-radius: 20%; /* 20% rounded corners */
}

.logout-button {
  /* margin-top: 5px; */
  background-color: white;
  max-width: 6vh; /* Set the maximum width to 10% of the header width */
  max-height: auto;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  justify-content: flex-end;
  padding-right: 5%;
}

.logout-image {
  /* max-width: 100%; */
  /* width: 45%;
  height: 45%; */
  max-width: 40px;
  max-height: 40px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
  cursor: pointer;
}

.header-container {
  display: flex;
  justify-content: space-between;
}

.logout-button, .menu {
  width: calc(50% - 20%);
}

/* Add rounded text box styles */
.rounded-textbox input {
  width: 60vw;
  height:5vh;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 16px;
  background-color: #dad5d5;
}

/* Adjust input border color on invalid input */
.rounded-textbox input:invalid {
  border-color: rgb(187, 180, 180);
}

/* Adjust input border color on focus */
.rounded-textbox input:focus {
  outline: none;
  border-color: #dad5d5;
}

/* Add rounded corners and grey background to select dropdown */
/* Style the custom dropdown wrapper */
.custom-dropdown {
  position: relative;
  width: 60vw;
  border-radius: 20px;
  overflow: hidden;
  align-items: center;
}

/* Style the select dropdown */
.custom-dropdown select {
  width: 100%;
  padding: 10px; /* Adjust padding as needed */
  border: none;
  background-color: #dad5d5; /* Grey background color */
  box-sizing: border-box; /* Ensure the width includes padding and border */
}

/* Style the arrow icon */
.custom-dropdown::after {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

/* Optional: Hover effect */
.custom-dropdown select:hover {
  background-color: #dad5d5; /* Light grey background color on hover */
}

.custom-form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Adds margin between each section */
}

/* Webcam Component */

/* temp container */
.temp-container {
  height: 300px;
  width: 400px;
  margin: 0 auto;
  position: relative;
  top: 10px;
}

/* webcam container */
.webcam-container {
  height: 300px;
  width: 400px;
  margin: 0 auto;
  position: relative;
  top: 10px;
}

.mainImg {
  height: 0px;
  width: 0px;
  display:'hidden';
}

.yes-no-buttons-container {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
}

.yes-no-buttons {
  display: flex;
}

.yes-no-buttons button {
  background-color: blue;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px; /* Add some margin between the buttons */
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Apply rotation animation */
  margin-top: 20%;
}

.loading-spinner-container-auth {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner-auth {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Apply rotation animation */
  margin-top: 20px;
}

.loading-spinner-container-request {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner-request {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Apply rotation animation */
}

.google-container {
  position: fixed;
}

.spinner {
  position: fixed;
  left: 45%;
}

.loading-spinner-camera {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Apply rotation animation */
  margin-top: 30px;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/***************************************************************************/

/* Responsive styles */
@media screen and (max-width: 768px) {
  .company-logo {
    max-width: 60px;
  }

  .app-title {
    font-size: 20px;
  }

  .multi-step-menu li {
    width: 30px;
    height: 30px;
    font-size: 14px;
    margin: 0 3px;
  }

  .footer button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .footer button:nth-child(1)::before,
  .footer button:nth-child(3)::after {
    font-size: 14px;
  }
  .welcome-back {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

  .auth-button {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 70%; /* Adjust submit button width for smaller screens */
    height: 30%;
  }

  .auth-image {
    width: 80%;
    height: 20%;/* Adjust submit image width for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .company-logo {
    max-width: 60px;
  }

  .app-title {
    font-size: 18px;
    padding-left: 10%;
  }

  .multi-step-menu li {
    width: 25px;
    height: 25px;
    font-size: 12px;
    margin: 0 2px;
  }

  .footer button {
    padding: 8px 16px;
    font-size: 12px;
  }

  .footer button:nth-child(1)::before,
  .footer button:nth-child(3)::after {
    font-size: 12px;
  }
  .welcome-back {
    font-size: 18px; /* Adjust font size for smaller screens */
  }

  .auth-button {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 70%; /* Adjust submit button width for smaller screens */
    height: 30%;
  }

  .auth-image {
    width: 80%;
    height: 20%;/* Adjust submit image width for smaller screens */
  }
}


.google-login-button {
  /* Default button styles */
  background-color: #fff;
  border: 2px solid white;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.google-login-image {
  /* Default image styles */
  width: 70%; /* Adjust based on your design */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Add spacing between image and button text */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

@media (max-width: 768px) {
  .google-login-button {
    font-size: 12px;
    padding: 6px 12px;
  }

  .google-login-image {
    width: 60%; /* Adjust image size for even smaller screens */
    margin-right: 6px; /* Reduce spacing for even smaller screens */
  }
}

@media (max-width: 480px) {
  .google-login-button {
    font-size: 12px;
    padding: 6px 12px;
  }

  .google-login-image {
    width: 60%; /* Adjust image size for even smaller screens */
    margin-right: 6px; /* Reduce spacing for even smaller screens */
  }
}

@media print {
  .hide-on-print {
    display: none !important;
  }
}

@media (max-height: 400px) {
  .google-login-button {
    font-size: 12px;
    padding: 6px 12px;
  }

  .google-login-image {
    width: 150px;
    margin-right: 6px;
  }
}


/* Select button styles */
.select-button {
  width: 100%; /* Adjust width as needed */
  padding: 15px; /* Adjust padding for better appearance */
  border: none;
  border-radius: 20px;
  font-size: 18px; /* Adjust font size */
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Add transitions for smoother effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add shadow effect */
  margin-top: 20%;

  @media (max-width: 768px), (max-height: 768px) {
    /* Adjust button size for smaller screens */
    width: 100%; /* Adjust width as needed */
    font-size: 16px; /* Adjust font size */
    margin-top: 20%;
  }
}

.select-button:hover {
  background-color: #45a049; /* Darken button slightly on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Adjust shadow on hover */
}
.select-button:selected {
  background-color: #1f4c22; /* Darken button slightly on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Adjust shadow on hover */
}
/* Gray color for disabled buttons */
.select-button:disabled {
  background-color: #ccc; /* Gray background color */
  color: #666; /* Darker gray text color */
  /* cursor: not-allowed; Show not-allowed cursor */
}
/* @media (max-width: 768px) {
  .submit-button-fixed {
    font-size: 0.8rem;
    padding: 0.8rem 1.5rem;
  }
}

@media (max-width: 480px) {
  .submit-button-fixed {
    font-size: 0.7rem;
    padding: 0.6rem 1rem;
  }
} */

.language-container {
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 1000;
}

.language-selector {
    position: absolute;
    top: 25%;
    right: 3%;
  }

/* .language-button {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
} */

.language-button {
  background: none;
  border: none;
  color: black;
  font-size: 14px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  padding: 10px 15px;
  gap: 8px;
  width: 140px;
  min-width: 120px;
  max-width: 200px;
}

/* Tablet: Adjust size for screens smaller than 1024px */
@media screen and (max-width: 1024px) {
  .language-button {
    width: 130px;
    font-size: 13px;
    padding: 8px 12px;
  }
}

/* Mobile: Adjust size for screens smaller than 768px */
@media screen and (max-width: 768px) {
  .language-selector {
    position: absolute;
    top: 25%;
    right: 3%;
  }
  .language-button {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
  }
}

/* Small Mobile: Adjust for very small screens (like iPhone SE) */
@media screen and (max-width: 480px) {
  .language-selector {
    position: absolute;
    top: 25%;
    right: 3%;
  }
  .language-button {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
  }
}

.language-dropdown {
  position: absolute;
  top: 100%;
  right: 0; /* Align dropdown to the right */
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  list-style: none;
  padding: 10px 0;
  margin: 0;
  min-width: 100px;
}

.language-dropdown li {
  padding: 12px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.language-dropdown li.active {
font-weight: bold;
}

.language-dropdown li:hover {
  background: #f0f0f0;
}

.flag-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #ccc;
}
/* Style the custom dropdown wrapper */
.custom-dropdown {
  position: relative;
  width: 100%; /* Ensure full-width usage */
  max-width: 350px; /* Limit max width to avoid stretching too much on larger screens */
  border-radius: 20px;
  overflow: hidden;
}

/* Style the select dropdown */
.custom-dropdown select {
  width: 100%; /* Make dropdown take full width */
  padding: 10px; /* Adjust padding as needed */
  border: none;
  background-color: #dad5d5; /* Grey background color */
  box-sizing: border-box; /* Ensure the width includes padding and border */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .welcome-container {
    margin: 10% auto;
    padding: 15px;
    width: 90%; /* Allow more space for smaller screens */
  }

  .welcome-form {
    gap: 12px;
  }

  .form-group label {
    font-size: 14px;
  }

  .radio-group label {
    font-size: 14px;
  }

  .back-button,
  .continue-button {
    width: 90%; /* Adjust buttons to fit smaller screen */
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .welcome-container {
    margin: 15% auto;
    padding: 10px;
    width: 95%; /* Maximize width on smaller screens */
  }

  .form-group label {
    font-size: 12px;
  }

  .radio-group label {
    font-size: 12px;
  }

  .back-button,
  .continue-button {
    width: 100%; /* Full width buttons for smaller screens */
    padding: 6px 12px;
    font-size: 12px;
  }
}

.welcome-container {
  max-width: 410px;
  margin: 10% auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f8f9fc; /* Light background */
  border-radius: 10px;
  justify-content: center; /* Vertically center content */
  flex-direction: column; /* Align child elements in a column */
  align-items: center; /* Horizontally center content */
}

.welcome-container h1 {
  text-align: left;
  color: #333;
  font-size: 22px;
  margin-bottom: 50px;
}

.welcome-form {
  display: flex;
  flex-direction: column;
  gap: 1%;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-weight: bold;
  color: #0c0c0b;
  font-size: 14px;
}

.radio-group {
  display: flex;
  gap: 10px;
}

.radio-group label {
  background-color: #e5e5e5;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.radio-group input {
  display: none;
}

.radio-group input:checked + label {
  background-color: #4c8bf5;
  color: #fff;
}

select {
  max-width: 300px;
  width: 100%; /* Allow the select to take up full width */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  background-color: #fff;
}

.form-actions {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.back-button,
.continue-button {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px;
  padding: 20px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  background-color: #4CAF50;
}

.back-button {
  background-color: #e0e0e0;
  color: #333;
}

.continue-button {
  background-color: #4CAF50;
  color: #fff;
  font-weight: bold;
}

.continue-button:hover {
  background-color: #88c78b;
}

.back-button:hover {
  background-color: #bbb;
}

.toggle-group {
  display: flex;
  gap: 10px;
}

.toggle-btn {
  background-color: #e0e0e0;
  color: #080808;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.toggle-btn.selected {
  background-color: #4CAF50;
  color: white;
}

.form-group {
  margin-bottom: 25px;
  text-align: left;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Add rounded text box styles */
.rounded-textbox input {
  width: 100%;
  height: 5vh;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 16px;
  background-color: #dad5d5;
}

input,
select,
button {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 5px;
}

input:hover,
input:focus,
select:hover,
select:focus {
  border: 2px solid #4caf50;
}
.alert-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.25); /* Slightly lighter overlay */
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}

.alert-box {
	background: #f9f9f9;
	width: 40vw; /* Fixed width */
	max-width: 80vw; /* Allows it to adapt to smaller screens */
	padding: 5px 0 2px;
	border-radius: 12px;
	text-align: center;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
}

/* Text and button styles remain the same */
.alert-message {
	font-size: 17px;
	font-weight: 600;
	color: #09090a;
	margin: 10px 20px 8px;
	line-height: 1.5;
	font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.alert-subtext {
	font-size: 13px;
	color: #00000a;
	margin: 0 20px 15px;
	line-height: 1.3;
	font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 300;
	font-style: normal;
	letter-spacing: 0.2px;
}

.alert-divider {
	width: 100%;
	border-top: 1px solid #d1d1d6;
	margin: 0;
}

.alert-buttons {
	display: flex;
	justify-content: space-around;
	margin-top: 5px;
}

.alert-button {
	flex: 1;
	border: none;
	background: transparent;
	font-size: 16px;
	font-weight: 400;
	color: #007aff;
	padding: 0 0;
	cursor: pointer;
	font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	display: flex;
	align-items: center;
	justify-content: center;
}

.alert-button:not(:last-child) {
	border-right: 1px solid #d1d1d6;
	height: 100%;
}

.alert-button.cancel {
	color: #007aff;
}

.alert-button.confirm {
	color: #007aff;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
	.alert-box {
		width: 80vw; /* Adjust to 80% of the viewport width */
	}
}
